:root {
    --themeColor: #2E5FB5;
    --darkColor: #02358F;
    --lightColor: #00B2FF;
    --gradient: linear-gradient(218deg, rgba(52, 101, 187, 1) 0%, rgba(0, 51, 141, 1) 100%);
    --greenGradient: linear-gradient(218deg, #51CB56 0%, #58B02F 100%);
    --orangeGradient: linear-gradient(218deg, #FFA800 0%, #E79902 100%);
    --blueGradient: linear-gradient(218deg, #00B2FF 0%, #0096D7 100%);

    --white: #FFFFFF;
    --text: #5A5F69;
    --textPalceholder: #CCCCCC;
    --bg: #F3F6FA;
}
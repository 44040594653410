@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}

@media (max-width: 1399px) {}

@media (max-width: 1299px) {
  // .faWrapper {
  //   height: 180px;
  // }
}

@media (max-width: 1199px) {
  .nav-bar-list {
    gap: 15px !important;
  }

  .logo svg {
    width: 140px !important;
  }

  .footer-p {
    padding-left: 0px !important;
  }

  /* 

  .nav-bar-list {
    gap: 20px !important;
  }

  .footer-content>svg {
    width: 150px !important;
  }

  .footer-logo svg {
    width: 150px !important;
  }

  .footer-content>.gap-3 {
    gap: 0px !important;
  } */
}

@media (max-width: 991px) {
  .down-btn {
    display: none !important;
  }

  .nav-bar-list {
    display: none !important;
    z-index: 110;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--white);
  }
  .nav-bar-list > li {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-top: 1px solid var(--white);
  }
  .nav-bar-list > li.subMenu ul{
    display: block !important;
    width: 100%;
    position: relative !important;
    top: auto !important;
    border: none !important;
    padding-left: 40px;
    list-style-type: disc;
  }
  .nav-bar-list > li.subMenu ul li{
    list-style-type: disc;
    display: list-item !important;
  }
  .nav-bar-list .subMenu ul li a{
    border-bottom: none !important;
    padding-left: 0 !important;
  }
  .showToggle {
    position: absolute !important;
    top: 87px;
    left: 0;
    background: var(--themeColor) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #0ea3594f;
  }

  .showToggle div {
    border-bottom: 1px solid var(--white);
    outline: none;
    width: 100%;
    // padding: 20px 10px;
  }

  .showToggle a:hover {
    // color: var(--darkTextGradient);
  }

  .showToggle .active {
    // color: var(--darkTextGradient);
  }

  .showToggle.nav-bar-list a {
    background: none !important;
    padding: 10px 15px !important;
    display: flex;
    width: 100%;
  }

  .nav-bar-list a.active::before {
    background: transparent !important;
  }

  .sticky.py-2 {
    transition: all 0.4s ease-in-out;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  .banner-content-wrapper .main-heading {
    text-align: center;
  }

  header {
    padding: 5px 0 !important;
  }

  section#home {
    height: auto !important;
    min-height: auto !important;
  }

  .banner-content-wrapper {
    padding-top: 100px;
    padding-bottom: 130px;
    padding: 100px 100px 130px 100px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .successImgCol {
    display: none;
  }

  // .social-link {
  //   right: 13px;
  // }

  .container {
    max-width: 100% !important;
    padding-left: calc(var(--bs-gutter-x) * .5) !important;
    padding-right: calc(var(--bs-gutter-x) * .5) !important;
  }

  // .faWrapper {
  //   height: 150px;
  // }

  .common-space {
    padding: 30px 0;
  }

  .clientsColWrap {
    padding-bottom: 25px;
  }

  .abtColImgInner {
    display: flex;
    justify-content: center;
  }

  .main-heading {
    text-align: center;
  }

  .abtColContent>div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .abtColContent p {
    text-align: center;
  }

  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: block !important;
  }

  .item-container {
    background-image: none !important;
  }

  .success-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 50px 0 50px;
  }

  .success-btn {
    display: flex;
    justify-content: center;
  }

  .success-content .main-heading {
    font-size: 30px;
    line-height: 40px;
  }

  .success-content .secondary-heading {
    font-size: 20px;
    line-height: 30px;
  }

  .main-heading-client {
    font-size: 32px !important;
  }

  .ceoMessage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ceoMessage>* {
    text-align: center;
  }

  .containerContact {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .contact-heading .main-heading,
  .contect-with-you .main-heading {
    text-align: left;
  }

  .ContactFormWrapper {
    width: 95% !important;
  }

  .service-list-container {
    & svg {
      [fill] {
        fill: var(--themeColor) !important;
      }

      [stroke] {
        stroke: var(--themeColor) !important;
      }
    }
  }
  .secPg-content {
    padding-top: 100px !important;
  }
}

@media (max-width: 768px) {
  .banner-content-wrapper {
    padding: 70px 60px 90px 60px;
  }

  // .faWrapper {
  //   height: 100px;
  // }

}

@media (max-width: 767px) {
  .faWrapper {
    display: none;
  }

  .contact-wrapper {
    flex-wrap: wrap;
  }

  .contect-with-us {
    width: 100% !important;
  }

  .ContactFormWrapper {
    width: 100% !important;
  }

  .contect-with-you {
    width: 100% !important;
  }

}

@media (max-width: 576px) {
  .banner-content-wrapper {
    padding: 50px 0 100px 0;
  }

  .banner-content-wrapper .main-heading {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  #home .social-link {
    display: none;
  }

  .success-content {
    padding: 0 0px 0 0px;
  }

  .contact-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .footer-revised {
    gap: 20px;
  }

  .footer-revised>* {
    display: flex;
    justify-content: center;
  }

  .footer-op {
    text-align: center;
  }

}

@media (max-width: 420px) {

  .footer-row .col-5,
  .footer-row .col-7 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (max-width: 480px) {
  header .header-btn {
    display: none !important;
  }

  .main-heading {
    font-size: 27px !important;
  }

  .contact-heading .main-heading,
  .contect-with-you .main-heading {
    text-align: center;
  }

  .footer-op {
    opacity: 0.7;
    display: flex;
    justify-content: center;
  }

  .abtColContent {
    padding-left: 0 !important;
  }

  // .Ceo .gradient {
  //   font-size: 35px !important;
  // }

  .service-solution-list .gradient {
    font-size: 26px !important;
  }

  p {
    font-size: 14px !important;
  }

  .success-content .secondary-heading~div {
    justify-content: center !important;

  }


}

@media (max-width: 400px) {
  
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {}

@media (max-width: 1199px) {
  /* 
  .rtl .footer-logo svg,
  .rtl .footer-content svg {
    width: 150px !important;
  } */
  .client-wrap{
    padding: 30px 30px 30px 30px;
  }
}

@media (max-width: 991px) {

  /* .rtl .bird {
    right: 0px;
  } */
  .rtl .item-container {
    background-image: none !important;
  }
  .rtl .nav-bar-list .subMenu > a {
    padding-right: 20px !important;
    padding-left: 0px !important;
  }
  .rtl .nav-bar-list > li.subMenu ul {
    padding-left: 0px;
    padding-right: 40px;
  }
  .client-content {
    flex: 1 1 calc(25% - 35px);
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
}

@media (max-width: 767px) {
  .client-wrap {
    padding: 30px 30px 30px 30px !important;
    gap: 20px !important;
  }
  .client-content {
    flex: 1 1 calc(33% - 20px) !important;
    max-width: calc(33% - 20px) !important;
    min-width: calc(33% - 20px) !important;
  }
  .client-content span img{
    max-height: 90px !important;
}
}


@media (max-width: 640px) {
  .client-content {
    flex: 1 1 calc(50% - 20px) !important;
    max-width: calc(50% - 20px) !important;
    min-width: calc(50% - 20px) !important;
  }
}

@media (max-width: 480px) {
  .client-content {
    flex: 1 1 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .client-content span{
    max-width: 200px;
  }
  .client-content span img{
    max-height: 80px !important;
    
}
  /* 
  .rtl .animated-wrapper {
    flex-direction: row-reverse !important;
    direction: ltr !important;
  }

  .rtl .slick-prev,
  .rtl .slick-next {
    width: 40px !important;
    height: 40px !important;
    bottom: 62% !important;
  }

  .rtl .slick-next {
    right: 200px !important;
  }

  .rtl .slick-prev {
    left: 200px !important;

  } */
  .rtl .abtColContent {
    order: 2;
    padding-left: 0px !important;
  }
}

@media (max-width: 400px) {}